/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /****************
        **  Carousels
        *****************/

        var $status = $('.slick-arrows .slick-count');
        var $slickElement = $('.hero-carousel');
        $slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.text(i + ' of ' + slick.slideCount);
        });
        $slickElement.slick({
          rows: 0,
          arrows: true,
          prevArrow: $('.slick-arrows .prev'),
          nextArrow: $('.slick-arrows .next'),
          dots: false,
          infinite: true,
          speed: 600,
          fade: true,
          cssEase: 'linear',
          autoplay: true,
          autoplaySpeed: 6500
        });

        var $status2 = $('.slick-arrows .slick-count');
        var $slickElement2 = $('.gallery-carousel');
        $slickElement2.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.text(i + ' of ' + slick.slideCount);
        });
        $slickElement2.slick({
          rows: 0,
          arrows: true,
          prevArrow: $('.slick-arrows .prev'),
          nextArrow: $('.slick-arrows .next,button.next-slide'),
          dots: false,
          infinite: true,
          speed: 500,
          fade: true,
          cssEase: 'ease-out',
          autoplay: true,
          autoplaySpeed: 8000
        });

        $('.testimonials-carousel').slick({
          rows: 0,
          arrows: false,
          dots: true,
          infinite: true,
          speed: 800,
          fade: true,
          cssEase: 'linear',
          autoplay: true,
          autoplaySpeed: 9000
        });


        /****************
        **  Match Heights
        *****************/
        $('.testimonials-carousel .media').matchHeight();
        $('.gallery-carousel .media').matchHeight();

        /****************
        **  SVG swap out with PNG on incompatible browsers
        *****************/
        if (!Modernizr.svg) {
          $('img[src*="svg"]').attr('src', function() {
            return $(this).attr('src').replace('.svg', '.png');
          });
        }

        /****************
        **  Primary Navigation - overlay settings
        *****************/
        $('#PrimaryNavigation').on('shown.bs.collapse', function () {
          $('body').addClass('primary-nav-engaged');
          $('#PrimaryNavigation #menu-primary-navigation > li span.nav-heading').matchHeight();
        });
        $('#PrimaryNavigation').on('show.bs.collapse', function () {
          $('body').addClass('primary-nav-engaging');
        });
        $('#PrimaryNavigation').on('hide.bs.collapse', function () {
          $('body').removeClass('primary-nav-engaged primary-nav-engaging');
        });
        $(document).keyup(function(e) {
          if (e.keyCode === 27) { // escape key maps to keycode `27`
            $('#PrimaryNavigation').collapse('hide');
          }
        });

        /****************
        **  Primary Navigation - creating headings, mobile collapse toggling
        *****************/
        $("#PrimaryNavigation ul#menu-primary-navigation > li > a").replaceWith(function(){
          return $("<span class='nav-heading'>" + $(this).html() + "</span><span class='subnav-toggle'>" + $(this).html() + "<i class='fas fa-caret-down'></i></span>");
        });
        $("#PrimaryNavigation ul#menu-quicklinks-navigation > li > a").replaceWith(function(){
          return $("<span class='nav-heading'>" + $(this).html() + "</span>");
        });
        $( "#PrimaryNavigation #menu-primary-navigation > li .subnav-toggle" ).click(function(e) {
          $(this).parent('li.menu-item').toggleClass('active-open');
          $(this).next('ul.sub-menu').slideToggle();
        });


        /****************
        **  Close Alert Bar
        *****************/
        if( $('.alertbar').length ) {
          if (typeof Cookies.get('alertHider') === 'undefined') {
            $('body').addClass('alertbar-engaged');
          }
        }
        $(".alertbar .closealert").on('click', function (e) {
          e.preventDefault();
          $('body').removeClass('alertbar-engaged');
          Cookies.set('alertHider', true);
          return false;
        });


        $('#rng-do-calc').click(function(event){
          event.preventDefault();
          var ecogas_ccf = $('#ccf-annual').val();
          var ecogas_10 = $('#ecogas_10').val();
          var ecogas_25 = $('#ecogas_25').val();
          var ecogas_50 = $('#ecogas_50').val();
          var ecogas_100 = $('#ecogas_100').val();

          var ecogas_10_year = ecogas_ccf * ecogas_10;
          var ecogas_25_year = ecogas_ccf * ecogas_25;
          var ecogas_50_year = ecogas_ccf * ecogas_50;
          var ecogas_100_year = ecogas_ccf * ecogas_100;

          var ecogas_10_month = ecogas_10_year / 12;
          var ecogas_25_month = ecogas_25_year / 12;
          var ecogas_50_month = ecogas_50_year / 12;
          var ecogas_100_month = ecogas_100_year / 12;

          $('#ecogas_10_month').html("$"+parseFloat(ecogas_10_month).toFixed(2));
          $('#ecogas_25_month').html("$"+parseFloat(ecogas_25_month).toFixed(2));
          $('#ecogas_50_month').html("$"+parseFloat(ecogas_50_month).toFixed(2));
          $('#ecogas_100_month').html("$"+parseFloat(ecogas_100_month).toFixed(2));
          $('#ecogas_10_year').html("$"+parseFloat(ecogas_10_year).toFixed(2));
          $('#ecogas_25_year').html("$"+parseFloat(ecogas_25_year).toFixed(2));
          $('#ecogas_50_year').html("$"+parseFloat(ecogas_50_year).toFixed(2));
          $('#ecogas_100_year').html("$"+parseFloat(ecogas_100_year).toFixed(2));

          return false;
        });

        $('#rng-do-calc2').click(function(event){
          event.preventDefault();
          var ecogas_per = $('#ccf-percentage').val();
//           ecogas_per = ecogas_per / 100;
          var ecogas_cost_100 = $('#ecogas_cost_100').val();

          var ecogas_cost_month = ecogas_per * ecogas_cost_100;
          var ecogas_cost_year = ecogas_cost_month * 12;

          $('#ecogas_cost_month').html("$"+parseFloat(ecogas_cost_month).toFixed(2));
          $('#ecogas_cost_year').html("$"+parseFloat(ecogas_cost_year).toFixed(2));

          return false;
        });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Single Post
    'single_post': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#menu-primary-navigation .menu-news .sub-menu .menu-view-latest').removeClass('active');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // archive
    'archive': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#menu-primary-navigation .menu-news .sub-menu .menu-view-latest').removeClass('active');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // code of ethics
    'code_of_ethics': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#menu-primary-navigation .menu-about-us .sub-menu .menu-who-we-are').addClass('active');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

